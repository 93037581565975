<template>
  <div class="saveGiftBox" @scroll="pageScroll">
     <div class="header"></div> 
     <!-- 倒计时 -->
    <div class="timeBigBox">
        <p class="actStart" v-if="activeState == 1">{{ $t('turkeyDistance') }}</p>
        <p class="actStart" v-if="activeState == 2">{{ $t('turkeyStart') }}</p>
        <p class="actStart" v-if="activeState == 3">{{ $t('turkeyEnd') }}</p>
        <!-- <div class="timeBox" v-if="activeState == 1 || activeState == 2"> -->
        <div class="downTimeBox">
          <div>
            <span>{{ dayArr[0] }}</span>
            <span>{{ dayArr[1] }}</span>
            <div>{{ $t('turkeyDays') }}</div>
          </div>
          <span></span>
          <div>
            <span>{{ hourArr[0] }}</span>
            <span>{{ hourArr[1] }}</span>
            <div>{{ $t('turkeyHours') }}</div>
          </div>
          <span></span>
          <div>
            <span>{{ minuterArr[0] }}</span>
            <span>{{ minuterArr[1] }}</span>
            <div>{{ $t('turkeyMin') }}</div>
          </div>
        </div>
      </div>
     <!-- 顶部tab -->
     <div class="tabBox">
      <div :class="actTab ==  1 ? 'actTab' : ''" @click="changeActTab(1)">{{ $t('ramadan_2nd_title') }}</div>
      <div :class="actTab ==  2 ? 'actTab' : ''" @click="changeActTab(2)">{{ $t('ramadan_3rd_title') }}</div>
     </div> 
     <!-- tab1内容 -->
     <div class="leftTab" v-if="actTab == 1">
      <!-- 礼物 -->
      <div class="giftBox">
          <header>{{ $t('ramadan_2nd_gifttitle') }}</header>
          <div class="giftList">
            <div class="giftItem" v-for="item in giftData" :key="item.id">
              <div class="giftImgBox">
                <img :src="item.url" alt="">
              </div>
              <div class="giftNameBox">
                <p>{{ item.name }}</p>
                <p>{{ $t('ramadan_2nd_gift_ticket') }} * {{ item.tickets }}</p>
              </div>
            </div>
          </div>
      </div>
      <!-- 排行榜  -->
      <div class="rankBox">
        <div class="rankTabBox">
          <div :class="rankTab == 1 ? 'rankTabAct' : ''" @click="changeRankTab(1)">{{ $t('ramadan_2nd_tab1') }}</div>
          <div :class="rankTab == 2 ? 'rankTabAct' : ''" @click="changeRankTab(2)">{{ $t('ramadan_2nd_tab2') }}</div>
        </div>
        <!-- 送礼榜 收礼榜  送礼榜添加class sendBox展示钻石-->
        <div class="rankInfo rankInfoPadding20 " :class="rankTab == 1 ? 'sendBox' : ''">
          <div class="rankInfoTabBox">
            <div :class="totalTab == 1 ? 'rankInfoActLeft' : ''" @click="changeTotalTab(1)">{{ $t('ramadan_2nd_dailyranking') }}</div>
            <div :class="totalTab == 2 ? 'rankInfoActRight' : ''" @click="changeTotalTab(2)">{{ $t('ramadan_2nd_totalranking') }}</div>
            <i class="tips" @click="showPop(1)"></i>
          </div>
          <!-- 榜单内容 -->
          <div>
            <div class="dailyRankBox" v-if="totalTab == 1">
              <div class="timeBox" ref="timeBox">
                <div class="timeItem" ref="timeItem">
                  <span v-for="(item,index) in actDate" :key="index" :class="day == item ? 'actTimeItem' : ''" :ref="day == item ? 'actTimeItem':''" @click="changeDay(item)">{{ item.toString().substr(4) | addString }}</span>
                </div>
              </div>
              <div class="dailyRankBody" v-if="rankDataList.length > 0">
                <top1_3 :top1_3Info="rankDataList" :langs="langs"></top1_3>
              </div>
              <div class="rankNull" v-else></div>
            </div>
            <div class="totalRankBox"  v-if="totalTab == 2">
              <template v-if="totalDataList.length > 0">
                <top1_3 :top1_3Info="totalDataList" :langs="langs"></top1_3>
                <ul class="top3After">
                  <template v-for="(item,index) in totalDataList">
                    <li v-if="index > 2" :key="index">
                      <p class="rankingNum">{{ item.top |  zeroFillTop}}</p>
                      <p class="pictureBox" @click="skipUserInfo(item)"> 
                          <img @click="skipUserInfo(item)" :src="item.headIcon" v-if="item.headIcon" alt="">
                          <img v-else src="../../assets/saveGiftAr/userNull.png" alt="">
                          <img class="touxiangkuang" v-if="item.frameBigUrl" :src="item.frameBigUrl" alt="">
                      </p>
                      <div class="gifterRankingCenter">
                        <p class="userName">{{ item.name }}</p>
                        <p class="gradeIconBox">
                        <span :class="['vip', 'vip' + item.vipLv  + (langs == 'ar' ? 'ar' : '')]" v-if="item.vipLv > 0">{{ item.vipLv }}</span>
                        <span :class="['sub', 'sub' +  (langs == 'ar' ? 'ar' : 'en')]" v-if="item.vip == 1"></span>
                        <span :class="['psub', 'psub' + (langs == 'ar' ? 'ar' : 'en')]" v-if="item.svip == 1"></span>
                        <span v-if="item.wealthLv > 0" :class="['wealth', countClass(item.wealthLv) + (langs == 'ar' ? 'ar' : '')]">
                          <span>{{ item.wealthLv }}</span>
                        </span>
                        </p>
                      </div>
                      <p class="userMoney">{{item.score | formatNumber}}</p>
                    </li>
                  </template>
                </ul>
              </template>
              <template v-else>
                <div class="rankNull"></div>
              </template>
            </div>
            <!-- 个人信息 -->
            <div class="userInfoBox"  v-if="totalTab == 2">
              <!-- 个人送礼信息  -->
              <div class="userInfoGifter" v-if="totalTab == 2 && selfDataInfo">
                <p class="rankingNum">{{ selfDataInfo.top | topFilter}}</p>
                <p class="pictureBox"  @click="skipUserInfo(selfDataInfo)">
                  <img v-if="selfDataInfo.headIcon" :src="selfDataInfo.headIcon" alt="">
                  <img v-else src="../../assets/saveGiftAr/userNull.png" alt="">
                  <img class="touxiangkuang" v-if="selfDataInfo.frameBigUrl" :src="selfDataInfo.frameBigUrl" alt="">
                </p>
                <div class="gifterRankingCenter">
                  <p class="userName">{{ selfDataInfo.name }}</p>
                  <p class="gradeIconBox">
                    <span :class="['vip', 'vip' + selfDataInfo.vipLv + (langs == 'ar' ? 'ar' : '')]" v-if="selfDataInfo.vipLv > 0"></span>
                    <span :class="['sub', 'sub' + (langs == 'ar' ? 'ar' : '')]" v-if="selfDataInfo.vip == 1"></span>
                    <span :class="['psub', 'psub' + (langs == 'ar' ? 'ar' : '')]" v-if="selfDataInfo.svip == 1"></span>
                    <span v-if="selfDataInfo.wealthLv > 0" :class="['wealth', countClass(selfDataInfo.wealthLv) + (langs == 'ar' ? 'ar' : '')]">
                      <span>{{ selfDataInfo.wealthLv }}</span>
                    </span>
                  </p>
                </div>
                <p class="userMoney">{{selfDataInfo.score | formatNumber }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
     </div>
     <!-- tab2内容 -->
     <div class="rightTab" v-if="actTab ==2">
       <div class="bonusHead">
        <i class="tips"  @click="showPop(2)"></i>
        <p>{{Number(poolScore) | addThree}}</p>
       </div>
       <div class="bonusRank">
        <header class="bonusRankHead">
          <p>{{ $t('ramadan_3rd_ticket') }}</p>
        </header>
        <div class="bonusRankList">
          <ul class="top3After">
            <template v-for="(item,index) in poolDataList">
              <li :key="index">
                <p class="rankingNum">{{ item.top |  zeroFillTop}}</p>
                <p class="pictureBox" @click="skipUserInfo(item)">
                    <img v-if="item.headIcon" :src="item.headIcon" alt="">
                    <img v-else src="../../assets/saveGiftAr/userNull.png" alt="">
                    <img class="touxiangkuang" v-if="item.frameBigUrl" :src="item.frameBigUrl" alt="">
                </p>
                <div class="gifterRankingCenter">
                  <p class="userName">{{ item.name }}</p>
                  <p class="gradeIconBox">
                  <span :class="['vip', 'vip' + item.vipLv + (langs == 'ar' ? 'ar' : '')]" v-if="item.vipLv > 0">{{ item.vipLv }}</span>
                  <span :class="['sub', 'sub' + (langs == 'ar' ? 'ar' : 'en')]" v-if="item.vip == 1"></span>
                  <span :class="['psub', 'psub' + (langs == 'ar' ? 'ar' : 'en')]" v-if="item.svip == 1"></span>
                  <span v-if="item.wealthLv > 0" :class="['wealth', countClass(item.wealthLv) + (langs == 'ar' ? 'ar' : '')]">
                    <span>{{ item.wealthLv }}</span>
                  </span>
                  </p>
                </div>
                <p class="userMoney">{{item.score | formatNumber}}</p>
              </li>
              <div v-if="index == 9 && poolDataList.length >= 10" class="dangerBox">
                <span>{{$t('ramadan_3rd_danger')}}</span>
              </div>
            </template>
          </ul>
          <div class="userInfoBox">
            <div class="userInfoGifter">
                  <p class="rankingNum">{{ poolSelfDataInfo.top | topFilter}}</p>
                  <p class="pictureBox" @click="skipUserInfo(poolSelfDataInfo)">
                    <img :src="poolSelfDataInfo.headIcon" alt="" v-if="poolSelfDataInfo.headIcon">
                    <img v-else src="../../assets/saveGiftAr/userNull.png" alt="">
                    <img class="touxiangkuang" v-if="poolSelfDataInfo.frameBigUrl" :src="poolSelfDataInfo.frameBigUrl" alt="">
                  </p>
                  <div class="gifterRankingCenter">
                    <p class="userName">{{ poolSelfDataInfo.name }}</p>
                    <p class="gradeIconBox">
                      <span :class="['vip', 'vip' + poolSelfDataInfo.vipLv + (langs == 'ar' ? 'ar' : '')]" v-if="poolSelfDataInfo.vipLv > 0">{{ poolSelfDataInfo.vipLv }}</span>
                      <span :class="['sub', 'sub' + (langs == 'ar' ? 'ar' : '')]" v-if="poolSelfDataInfo.vip == 1"></span>
                      <span :class="['psub', 'psub' + (langs == 'ar' ? 'ar' : '')]" v-if="poolSelfDataInfo.svip == 1"></span>
                      <span v-if="poolSelfDataInfo.wealthLv > 0" :class="['wealth', countClass(poolSelfDataInfo.wealthLv) + (langs == 'ar' ? 'ar' : '')]">
                        <span>{{ poolSelfDataInfo.wealthLv }}</span>
                      </span>
                    </p>
                  </div>
                <p class="userMoney">{{poolSelfDataInfo.score | formatNumber}}</p>
            </div>
          </div>
        </div>
       </div>
     </div>
     <!-- 弹窗 -->
     <div class="popBox" v-if="popFlag > 0">
        <div class="popBody">
          <span class="closeBtn" @click="showPop(0)"></span>
          <!-- 榜单弹窗 -->
          <div class="rankPopBox" v-if="popFlag == 1">
            <div>
              <header>{{ $t('ramadan_2nd_reward_title1') }}</header>
              <div class="rankPopBody">
                <p>
                  <span>{{ $t('ramadan_2nd_reward_rank') }}</span>
                  <span>{{ $t('ramadan_2nd_dailyranking') }}</span>
                  <span>{{ $t('ramadan_2nd_totalranking') }}</span>
                </p>
                <div>
                  <span>{{ $t('ramadan_2nd_reward_top1') }}</span>
                  <span class="span2">
                    <span>
                      <img src="../../assets/saveGiftAr/top1Gift.png" alt="">
                      <span>{{ $t('ramadan_2nd_daily_supporter_reward') }} * 2{{ $t('ramadan_signin_days') }}</span>
                    </span>
                  </span>
                  <span class="span3">
                    <!-- <img src="../../assets/saveGiftAr/top1_2Gift.png" alt=""> -->
                      <span>{{ $t('ramadan_2nd_total_supporter_top1') }}</span>
                  </span>
                </div>
                <div>
                  <span>{{ $t('ramadan_2nd_reward_top2-3') }}</span>
                  <span class="span2">
                    <span>
                      <!-- <img src="../../assets/saveGiftAr/gift.png" alt=""> -->
                      <span style="line-height: 1rem;">-</span>
                    </span>
                  </span>
                  <span class="span3">
                    <!-- <img src="../../assets/saveGiftAr/gift.png" alt=""> -->
                  <span style="line-height: 0.2rem; padding-top: 0.3rem;">{{ $t('ramadan_2nd_total_supporter_top2-3') }}</span>
                  </span>
                </div>
                <div>
                  <span>{{ $t('ramadan_2nd_reward_top4-10') }}</span>
                  <span class="span2">
                    <span>
                      <!-- <img src="../../assets/saveGiftAr/gift.png" alt=""> -->
                      <span  style="line-height: 1rem;">-</span>
                    </span>
                  </span>
                  <span class="span3">
                    <!-- <img src="../../assets/saveGiftAr/gift.png" alt=""> -->
                    <span style="line-height: 0.2rem; padding-top: 0.3rem;">{{ $t('ramadan_2nd_total_supporter_top4-10') }}</span>
                  </span>
                </div>
              </div>
            </div>
            <div>
              <header>{{ $t('ramadan_2nd_reward_title2') }}</header>
              <div class="rankPopBody">
                <p>
                  <span>{{ $t('ramadan_2nd_reward_rank') }}</span>
                  <span>{{ $t('ramadan_2nd_dailyranking') }}</span>
                  <span>{{ $t('ramadan_2nd_totalranking') }}</span>
                </p>
                <div>
                  <span>{{ $t('ramadan_2nd_reward_top1') }}</span>
                  <span class="span2">
                    <span>
                      <img src="../../assets/saveGiftAr/top1_2Gift.png" alt="">
                      <span>{{ $t('ramadan_2nd_daily_anchor_reward') }}* 2{{ $t('ramadan_signin_days') }}</span>
                    </span>
                  </span>
                  <span class="span3">
                    <!-- <img src="../../assets/saveGiftAr/gift.png" alt=""> -->
                    <span>{{ $t('ramadan_2nd_total_anchor_top1') }}</span>
                  </span>
                </div>
                <div>
                  <span>{{ $t('ramadan_2nd_reward_top2-3') }}</span>
                  <span class="span2">
                    <span>
                      <!-- <img src="../../assets/saveGiftAr/gift.png" alt=""> -->
                      <span style="line-height: 1rem;">-</span>
                    </span>
                  </span>
                  <span class="span3">
                    <!-- <img src="../../assets/saveGiftAr/gift.png" alt=""> -->
                  <span style="line-height: 0.2rem; padding-top: 0.3rem;">{{ $t('ramadan_2nd_total_anchor_top2-3') }}</span>
                  </span>
                </div>
                <div>
                  <span>{{ $t('ramadan_2nd_reward_top4-10')}}</span>
                  <span class="span2">
                    <span>
                      <!-- <img src="../../assets/saveGiftAr/gift.png" alt=""> -->
                      <span style="line-height: 1rem;">-</span>
                    </span>
                  </span>
                  <span class="span3">
                    <!-- <img src="../../assets/saveGiftAr/gift.png" alt=""> -->
                      <span style="line-height: 0.2rem; padding-top: 0.3rem;">{{ $t('ramadan_2nd_total_anchor_top4-10') }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- 奖金池 -->
          <div class="bonusPopBox"  v-if="popFlag == 2">
            <header>{{ $t('ramadan_3rd_ruletitle') }}</header>
            <p>1.{{ $t('ramadan_3rd_rule1') }}</p>
            <p>2.{{ $t('ramadan_3rd_rule2') }}</p>
            <!-- 礼物 -->
            <div class="giftList">
              <div class="giftItem" v-for="item in giftData" :key="item.id">
                <div class="giftImgBox">
                  <img :src="item.url" alt="">
                </div>
                <div class="giftNameBox">
                  <p>{{ item.name }}</p>
                  <p>{{ $t('ramadan_2nd_gift_ticket') }} * {{ item.tickets }}</p>
                </div>
              </div>
            </div>
            <p>3.{{ $t('ramadan_3rd_rule3') }}</p>
          </div>
        </div>
        
     </div>
  </div>
 
</template>

<script>
import { Toast } from "vant";
import top1_3 from "../../components/top1_3.vue";
export default {
  name: "save_gift_ar",
  components:{
    top1_3
  },
  data() {
    return{
      // 活动tab
      actTab:1,
      // 榜单tab
      rankTab:1,
      // 日榜  总榜  tab
      totalTab:1,
      //礼物数据
      giftData:[],
      // 榜单数据
      rankDataList:[], //日榜
      totalDataList:[], //总榜
      selfDataInfo:{},//总榜个人信息
      // 当前天
      day:'20250310',
      curDay:'',
      // 当前页
      page:0,
      // 1送礼榜 2收礼榜
      type:'1',
      size:20,
      // 活动时间
      actDate:[20250301, 20250302, 20250303, 20250304, 20250305, 20250306,20250307,20250308,20250309,20250310,20250311,20250312],
      swiperObj2:null,
      // 避免重复请求
      notFast: true,
      // 奖池数量
      poolScore:0,
      // 奖池排行榜
      poolDataList:[],
      // 奖池当前自己排名
      poolSelfDataInfo:{},
      popFlag: 0,
      dayArr:[0,0],
      hourArr:[0,0],
      minuterArr:[0,0],
      activeState:1,
      activityObj:{
        endTime:'',
        startTime:''
      }

    }
  },
  created() { 
    //调用原生方法
    this.$completes.complete("getUserInfo");
    //原生调用h5方法
    window.getUserInfo = this.getUserInfo;
    window["getUserInfo"] = (data) => {
      this.getUserInfo(data);
    };
    // 判断平台
    this.judgeClient()

    // this.getUserInfo() 
  },
  filters:{
    addString(str) {
        return str.substring(0, 2) + '/' + str.substring(2);
    },
    formatNumber(num) {
      if(num){
        if (num > 1000000) {
            return Math.trunc((num / 1000000) * 10) / 10 + 'M';
        } else if (num >= 1000) {
            return Math.trunc((num / 1000) * 10) / 10 + 'K';
        } else {
            return num.toString ();
        }
      }else{
        return 0
      }
      
    },
    topFilter(top){
      if(!top){
        return '--'
      }else{
        return top > 9 ? top : '0' + top
      }
    },
    zeroFillTop(top){
      if(top < 10){
        return '0'+top;
      }else{
        return top;
      }
    },
    addThree(number){
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  methods: {
    // 计算活动时间
    countActivityTime(){
      let endTime = 0;
      if(this.activeState == 1){
        this.countdown(this.activityObj.startTime)
      }else{
        this.countdown(this.activityObj.endTime)
      }
      
      if(new Date().getTime() > this.activityObj.startTime && new Date().getTime() > this.activityObj.endTime){
        this.countdown(this.activityObj.endTime)
      }

    },
    // 根据时间戳转换倒计时
    countdown(endTime) {
      const now = new Date().getTime();
        const distance = endTime - now;
        // const days = this.zeroFill(Math.floor(distance / (1000 * 60 * 60 )) > 99 ? 99 : Math.floor(distance / (1000 * 60 * 60 )));
        const days = this.zeroFill(Math.floor(distance / (1000 * 60 * 60 * 24 )));
        const hours = this.zeroFill(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        // const hours = this.zeroFill(days);
        const minutes = this.zeroFill(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
        const seconds = this.zeroFill(Math.floor((distance % (1000 * 60)) / 1000));
        this.dayArr = days;
        this.hourArr = hours;
        this.minuterArr = minutes;
        // this.seconds = seconds;
    },
    zeroFill(num){
      if(num < 10){
        return [0,num]
      }else{
        return [num.toString().slice(0,1),num.toString().slice(1)]
      }
    },
    // 展示弹窗
    showPop(num){
      this.popFlag = num
    },
    // 获取奖池排行榜
    getPoolRankList(){
      this.notFast = false;
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIpJava +
          "api/activity/ramadanRank/pool/page?token=" +
          this.token +
          "&uid=" +
          this.uid +
          "&page=" +
          this.page +
          "&size=" +
          this.size,
        data: "",
        lang: this.langs,
        header: this.headers,
      };
      this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header).then((data) => {
        if (data.code == 200) {
            this.poolDataList = [...this.poolDataList,...data.data.list];
            //  是否还有下一页
            if(data.data.list.length == data.data.size){
              this.notFast = true
            }
        }else{
          Toast(data.message);
        }
      });
    },
    // 获取奖池当前自己排名
    getPoolSelfInfo(){
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIpJava +
          "api/activity/ramadanRank/pool/rank?token=" +
          this.token +
          "&uid=" +
          this.uid,
        data: "",
        lang: this.langs,
        header: this.headers,
      };
      this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header).then((data) => {
        if (data.code == 200) {
            this.poolSelfDataInfo = data.data;
        }else{
          Toast(data.message);
        }
      });
    },
    //分页
    pageScroll(e) {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      // 检查是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight) {
          if(this.notFast){
              this.page++;
              if(this.actTab == 1){
                if( this.type == 1){
                  this.getDayRank()
                }else{
                  this.getTotalRank();
                }
              }else{
                this.getPoolRankList()
              }
              
          }
      }
    },
    // 切换日期 从新请求榜单
    changeDay(item){
      this.day = item;
      this.rankDataList = [];
      this.page = 0;
      this.$nextTick(()=>{
        this.scrollToCenter()
        this.getDayRank()
      })
    },
    scrollToCenter() {
      const parent = this.$refs.timeBox;
      const element = this.$refs.actTimeItem[0]
      // 获取父元素的宽度
      const parentWidth = parent.clientWidth;
      // 计算元素应该滚动到的位置
      const targetOffset = element.offsetLeft - (parentWidth / 2) + (element.clientWidth / 2);
      // 设置父元素的 scrollLeft 属性
      // parent.scrollLeft = targetOffset;
      // 设置父元素的 scrollLeft 属性
      parent.scroll({
        left: targetOffset,
        behavior: 'smooth' // 平滑滚动
      });
    },
    // 切换日榜和总榜
    changeTotalTab(type){
      this.totalTab = type;
      this.type = type;
      this.day = this.curDay;
      this.page = 0;
      if( this.type == 1){
        this.rankDataList = [];
        this.$nextTick(()=>{
          this.scrollToCenter()
          this.getDayRank()
        })
      }else{
        this.getTotalRank();
        this.getSelfInfo();
      }
    },
    // 切换榜单tab
    changeRankTab(type){
      this.rankTab = type;
      this.rankDataList = [];
      this.selfDataInfo = {};
      this.totalDataList = [];
      this.changeTotalTab(1)
    },
    // 切换活动tab
    changeActTab(type){
      this.actTab = type;
      this.page = 0;
      if(type == 2){
        this.poolDataList = [];
        this.getPoolRankList();
        this.getPoolSelfInfo();
      }else{
        this.changeRankTab(1)
      }
    },
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      let langs = this.userInfo.lang.substring(0,2)
      this.langs = this.userInfo.lang.substring(0,2)
      let lang = this.userInfo.lang
      this.headers = {
          fid:  this.userInfo.fid,
          os:  this.userInfo.os || 1,
          lang: this.userInfo.lang, 
          version:this.userInfo.version,
          deviceid:this.userInfo.deviceId,
        }
    // this.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJOemt6TWpJNCIsInh5eiI6IlEwWkRRakJCTURBdFJEYzJOaTAwTURZMkxUaEJRamd0UkVaR01rVXhSRFpCUWpjMSIsImV4cCI6MTk5MjkzODIyNn0.-8-fxCL-8HULEkCIUdI9AaLZEBYdDwi9ZHC3cjhxa5Y';
    //   this.uid = '793228'; 
    //   this.headers = {
    //       fid: '2001',
    //       os: 1,
    //       lang: 'zh-CN',
    //       version: '3.0',
    //       deviceid: 'CFCB0A00-D766-4066-8AB8-DFF2E1D6AB75',
    //   }
    //   let langs = 'en'
    //   this.langs = langs
    //   let lang = 'zh_CN'
      document.title = this.$t('ramadan_title')
      if (langs == "ar") {
          this.$i18n.locale = "ar";
      }else {
          this.$i18n.locale = "en";
      }
      document.querySelector("html").setAttribute("lang", this.$i18n.locale);
      this.getActInfo()
    },
    // 获取活动信息
    getActInfo(){
      // 获取活动时间
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIpJava +
          "api/activity/ramadanRank/init?token=" +
          this.token +
          "&uid=" +
          this.uid,
        data: "",
        lang: this.langs,
        header: this.headers,
      };
      this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header).then((data) => {
        if (data.code == 200) {
          this.activityObj.startTime = data.data.startTime;
          this.activityObj.endTime = data.data.stopTime;
          this.giftData = data.data.gifts;
          this.day = data.data.curDay;
          this.curDay = data.data.curDay;
          this.poolScore = data.data.poolScore;
          this.actDate = data.data.dayList;
          this.$nextTick(()=>{
            this.scrollToCenter()
          })
          this.getDayRank()
          if( this.activityObj.endTime > new Date().getTime() && this.activityObj.startTime < new Date().getTime()) {
              this.activeState = 2;
              // 设置倒计时
              this.countActivityTime()
          } else if(this.activityObj.startTime > new Date().getTime()){
              this.activeState = 1;
              // 设置倒计时
              this.countActivityTime()
          } else {
              this.activeState = 3;
          }
            
        }else{
          Toast(data.message);
        }
      });
    },
    // 获取日榜
    getDayRank(){
      this.notFast = false;
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIpJava +
          "api/activity/ramadanRank/day/page?token=" +
          this.token +
          "&uid=" +
          this.uid +
          "&page=" +
          this.page +
          "&type=" +
          this.rankTab +
          "&day=" +
          this.day+
          "&size=" +
          this.size,
        data: "",
        lang: this.langs,
        header: this.headers,
      };
      this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header).then((data) => {
        if (data.code == 200) {
            this.rankDataList = [...this.rankDataList,...data.data.list];
            //  是否还有下一页
            if(data.data.list.length == data.data.size){
              this.notFast = true
            }
        }else{
          Toast(data.message);
        }
      });
    },
    // 获取总榜
    getTotalRank(){
      this.notFast = false;
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIpJava +
          "api/activity/ramadanRank/total/page?token=" +
          this.token +
          "&uid=" +
          this.uid +
          "&page=" +
          this.page +
          "&type=" +
          this.rankTab +
          "&size=" +
          this.size,
        data: "",
        lang: this.langs,
        header: this.headers,
      };
      this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header).then((data) => {
        if (data.code == 200) {
          this.totalDataList = [...this.totalDataList,...data.data.list];
          //  是否还有下一页
          if(data.data.list.length == data.data.size){
            this.notFast = true
          }
        }else{
          Toast(data.message);
        }
      });
    },
    // 获取总榜个人信息
    getSelfInfo(){
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIpJava +
          "api/activity/ramadanRank/total/rank?token=" +
          this.token +
          "&uid=" +
          this.uid +
          "&type=" +
          this.type ,
        data: "",
        lang: this.langs,
        header: this.headers,
      };
      this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header).then((data) => {
        if (data.code == 200) {
           this.selfDataInfo = data.data
        }else{
          Toast(data.message);
        }
      });
    },
    // 判断平台
    judgeClient() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
        this.client = 'iOS';
      } else if (/(Android)/i.test(navigator.userAgent)) { //判断Android
        this.client = 'Android';
      } else {
        this.client = 'PC';
      }
      return this.client;
    },
    // 跳转资料页  需要uid
    skipUserInfo(item) {
      if (this.client == 'iOS') {
        window["webkit"].messageHandlers['skipUserInfo'].postMessage(JSON.stringify(item.uid));
      } else if (this.client == 'Android') {
        nativeJs["skipUserInfo"](JSON.stringify(item.uid));
      }
    },
    
  }
};
</script>

<style scoped>
@import './index.css';
@import '../../assets/gradeIcon/gradeIcon.css';
</style>
