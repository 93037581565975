<template>
    <div class="top1_3Box">
        <div class="top2Box">
          <div class="picBox">
            <template v-if="top1_3Info[1]">
              <img class="picUser" v-if="top1_3Info[1].headIcon" :src="top1_3Info[1].headIcon" alt="" >
              <img v-else class="picUser" src="../assets/saveGiftAr/userNull.png" alt="">
              <img class="picKuang" src="../assets/saveGiftAr/top2Kuang.png" alt="" @click="skipUserInfo(top1_3Info[1])">
            </template>
            <template v-else>
              <img class="picUser" src="../assets/saveGiftAr/userNull.png" alt="">
              <img class="picKuang" src="../assets/saveGiftAr/top2Kuang.png" alt="">
            </template>
          </div>
          <div class="userName" v-if="top1_3Info[1]">
            {{ top1_3Info[1].name }}
          </div>
          <div class="userName" v-else>
            --
          </div>
          <div class="levelBox">
            <span :class="['vip', 'vip' + top1_3Info[1].vipLv + (langs == 'ar' ? 'ar' : '')] " v-if="top1_3Info[1] && top1_3Info[1].vipLv > 0"></span>
            <span :class="['sub', 'sub' + (langs == 'ar' ? 'ar' : 'en')]" v-if="top1_3Info[1] && top1_3Info[1].vip == 1"></span>
            <span :class="['psub', 'psub' + (langs == 'ar' ? 'ar' : 'en')]" v-if="top1_3Info[1] && top1_3Info[1].svip == 1"></span>
             <span v-if="top1_3Info[1] && top1_3Info[1].wealthLv > 0" :class="['wealth', countClass(top1_3Info[1].wealthLv) + (langs == 'ar' ? 'ar' : '')]">
              <span>{{ top1_3Info[1].wealthLv }}</span>
            </span>
          </div>
          <p class="zuanshiBox" v-if="top1_3Info[1]">{{top1_3Info[1].score | formatNumber }}</p>
          <!-- <div class="levelBox">
            <span :class="['vip', 'vip' + 4 + (langs == 'ar' ? 'ar' : '')] "></span>
            <span :class="['sub', 'sub' + (langs == 'ar' ? 'ar' : 'en')]"></span>
            <span :class="['wealth', countClass(20) + (langs == 'ar' ? 'ar' : '')]">
              <span>20</span>
            </span>
          </div>
          <p class="zuanshiBox" >{{95623482 | formatNumber }}</p> -->
        </div>
        <div class="top2Box top1Box">
          <div class="picBox" >
            <template v-if="top1_3Info[0]">
              <img class="picUser" v-if="top1_3Info[0].headIcon" :src="top1_3Info[0].headIcon" alt="" >
              <img v-else class="picUser" src="../assets/saveGiftAr/userNull.png" alt="">
              <img class="picKuang" src="../assets/saveGiftAr/top1Kuang.png" alt="" @click="skipUserInfo(top1_3Info[0])">
            </template>
            <template v-else>
              <img class="picUser" src="../assets/saveGiftAr/userNull.png" alt="">
              <img class="picKuang" src="../assets/saveGiftAr/top1Kuang.png" alt="">
            </template>
          </div>
          <div class="userName" v-if="top1_3Info[0]">
            {{ top1_3Info[0].name }}
          </div>
          <div class="userName" v-else>
            --
          </div>
          <div class="levelBox">
            <span :class="['vip', 'vip' + top1_3Info[0].vipLv + (langs == 'ar' ? 'ar' : '')] " v-if="top1_3Info[0] && top1_3Info[0].vipLv > 0"></span>
            <span :class="['sub', 'sub' + (langs == 'ar' ? 'ar' : 'en')]" v-if="top1_3Info[0] && top1_3Info[0].vip == 1"></span>

            <span :class="['psub', 'psub' + (langs == 'ar' ? 'ar' : 'en')]" v-if="top1_3Info[0] && top1_3Info[0].svip == 1"></span>
             <span v-if="top1_3Info[0] && top1_3Info[0].wealthLv > 0" :class="['wealth', countClass(top1_3Info[0].wealthLv) + (langs == 'ar' ? 'ar' : '')]">
              <span>{{ top1_3Info[0].wealthLv }}</span>
            </span>
          </div>
          <p class="zuanshiBox" v-if="top1_3Info[0]">{{top1_3Info[0].score | formatNumber }}</p>
        </div>
        <div class="top2Box top3Box" >
          <div class="picBox">
            <template v-if="top1_3Info[2]">
              <img class="picUser" v-if="top1_3Info[2].headIcon" :src="top1_3Info[2].headIcon" alt="">
              <img v-else class="picUser" src="../assets/saveGiftAr/userNull.png" alt="">
              <img class="picKuang" src="../assets/saveGiftAr/top3Kuang.png" alt="" @click="skipUserInfo(top1_3Info[2])">
            </template>
            <template v-else>
              <img class="picUser" src="../assets/saveGiftAr/userNull.png" alt="">
              <img class="picKuang" src="../assets/saveGiftAr/top3Kuang.png" alt="">
            </template>
          </div>
          <div class="userName" v-if="top1_3Info[2]">
            {{ top1_3Info[2].name }}
          </div>
          <div class="userName" v-else>
            --
          </div>
          <div class="levelBox">
            <span :class="['vip', 'vip' + top1_3Info[2].vipLv + (langs == 'ar' ? 'ar' : '')] " v-if="top1_3Info[2] && top1_3Info[2].vipLv > 0"></span>
            <span :class="['sub', 'sub' + (langs == 'ar' ? 'ar' : 'en')]" v-if="top1_3Info[2] && top1_3Info[2].vip == 1"></span>
            <span :class="['psub', 'psub' + (langs == 'ar' ? 'ar' : 'en')]" v-if="top1_3Info[2] &&top1_3Info[2].svip == 1"></span>
             <span v-if="top1_3Info[2] && top1_3Info[2].wealthLv > 0" :class="['wealth', countClass(top1_3Info[2].wealthLv) + (langs == 'ar' ? 'ar' : '')]">
              <span>{{ top1_3Info[2].wealthLv }}</span>
            </span>
          </div>
          <p class="zuanshiBox" v-if="top1_3Info[2]">{{top1_3Info[2].score | formatNumber }}</p>
        </div>
    </div>
</template>
<script>
export default{
    name:'top1_3',
    props:{
      langs:{
        default:'en'
      },
      top1_3Info:{

      }
    },
    created(){
      // 判断平台
      this.judgeClient()
    },
    filters:{
        formatNumber(num) {
            if(num){
                if (num > 1000000) {
                    return Math.trunc((num / 1000000) * 10) / 10 + 'M';
                } else if (num >= 1000) {
                    return Math.trunc((num / 1000) * 10) / 10 + 'K';
                } else {
                    return num.toString ();
                }
            }
        },
    },
    methods:{
      // 跳转资料页  需要uid
      skipUserInfo(item) {
        if (this.client == 'iOS') {
          window["webkit"].messageHandlers['skipUserInfo'].postMessage(JSON.stringify(item.uid));
        } else if (this.client == 'Android') {
          nativeJs["skipUserInfo"](JSON.stringify(item.uid));
        }
      },
    // 判断平台
    judgeClient() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
        this.client = 'iOS';
      } else if (/(Android)/i.test(navigator.userAgent)) { //判断Android
        this.client = 'Android';
      } else {
        this.client = 'PC';
      }
      return this.client;
    },
    }
}
</script>
<style scoped>
.top1_3Box{
  width: 608px;
  /* height: 304px; */
  margin: 0 auto 0;
  display: flex;
  justify-content: space-between;
  padding-top: 66px;
}

.top1_3Box>div{
    width: 200px;
}
html[lang="ar"] .zuanshiBox::before{
    display: none;
}

html[lang="ar"] .zuanshiBox::after{
    content: '';
    display: inline-block;
    width: 23px;
    height: 23px;
    background: url('../assets/saveGiftAr/qian.png') no-repeat;
    background-size: 100% 100%;
    vertical-align: top;
    margin-right: 4px;
    margin-left: 4px;
}

.zuanshiBox::before{
    content: '';
    display: inline-block;
    width: 23px;
    height: 23px;
    background: url('../assets/saveGiftAr/qian.png') no-repeat;
    background-size: 100% 100%;
    vertical-align: top;
    margin-right: 4px;
    margin-left: 4px;
}
.picBox img{
  border-radius: 50%;
}
</style>